<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.trainings") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr
              :class="{ 'selected-row': $helpers.get(table.selectedRow, 'id') === row.item.id }"
              style="cursor: pointer;"
              @click="onRowClick(row)"
            >
              <td>
                {{ row.item.name }}
              </td>
              <td>
                {{ row.item.training.name }}
              </td>
              <td>
                {{ $t(`hr.training.training_type.${row.item.trainingType}`) }}
              </td>
              <td>
                {{ row.item.organiser && row.item.organiser.firstName }}
                {{ row.item.organiser && row.item.organiser.lastName }}
              </td>
              <td>
                {{
                  row.item.externalLecturer
                    ? row.item.externalLecturer
                    : row.item.lecturer.firstName + " " + row.item.lecturer.lastName
                }}
              </td>
              <td>
                {{ (row.item.terminal || {}).name }}
              </td>
              <td>{{ row.item.trainingSessions.length }} {{ $t("hr.training.session") }}</td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @closed:detail="table.selectedRow = null" @detail-loaded:success="table.selectedRow = $event" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { TRAININGS_PAGINATE } from "../../query";

  export default {
    name: "Trainings",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          participant: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        selectedRow: null,
        headers: [
          {
            text: vm.$t("hr.training.plan"),
            align: "left",
            sortable: false,
            value: "name",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.training.name"),
            align: "left",
            sortable: false,
            value: "training.name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.type"),
            align: "left",
            sortable: false,
            value: "trainingType",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.organizer"),
            align: "left",
            sortable: false,
            value: "organizer.firstName",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.lecturer"),
            align: "left",
            sortable: false,
            value: "lecturer.firstName",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.terminal"),
            align: "left",
            sortable: false,
            value: "terminal.name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.sessions"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRowClick(row) {
        this.table.selectedRow = row.item;
        this.navigateToDetail();
      },
      navigateToDetail() {
        let routerParams = {
          id: this.$helpers.encodeID(this.table.selectedRow.id)
        };
        this.$router.push({ name: "trainings.detail", params: routerParams });
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: TRAININGS_PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateTrainingPlans.content || [];
              this.table.totalItems = data.filterWithPaginateTrainingPlans.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateTrainingPlans.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
